// extracted by mini-css-extract-plugin
export var hero = "Hero-module--hero--3Zjdi";
export var hasBackgroundDotted = "Hero-module--has-background-dotted--8nbDc";
export var hasRoundedCorner = "Hero-module--has-rounded-corner--2d-i0";
export var h1 = "Hero-module--h1--2Nrrx";
export var subtitle = "Hero-module--subtitle--3M8rA";
export var hasBackgroundImage = "Hero-module--has-background-image--17gky";
export var backgroundImage = "Hero-module--background-image--RDEz-";
export var videoWrapper = "Hero-module--videoWrapper--3QFxt";
export var video = "Hero-module--video--1i8jS";
export var paddingBottom = "Hero-module--paddingBottom--128Vz";
export var expandContainerIsFull = "Hero-module--expandContainerIsFull--3xLBN";
export var expandContainerIsOneThird = "Hero-module--expandContainerIsOneThird--rO1iQ";
export var spinAround = "Hero-module--spinAround--2n7_L";