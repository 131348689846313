import React from 'react';
import Header from './Header/Header';
import Footer from './Footer';
import Contact from './Contact';
import { Helmet } from 'react-helmet'
import favicon16 from "../assets/favicon16.png";
import favicon32 from "../assets/favicon32.png";
import favicon64 from "../assets/favicon64.png";
import ogImage from "../assets/ogImages/Allgemein.png";
import { useStaticQuery, graphql } from 'gatsby';
import { Colors } from '../utils/Types';
import Gdpr from './Gdpr/Gdpr';
import MiniCart from './Cart/MiniCart';
import { defaultsDeep } from 'lodash';

export interface IMetadata {
    title?: string;
    description?: string;
    ogImage?: string;
    ogTitle?: string;
    ogDescription?: string;
}
type Props = {
    crumbs: Array<{label: string, url: string}>;
    children: any;
    location?: any;
    metadatas?: IMetadata;
    hasWave?: boolean;
    headerColor?: Colors;
    waveBgColor?: Colors;
    breadcrumbColor?: "light" | "dark";
    hideContact?: boolean;
    classNameHeader?: string | Array<string>;
    disableMiniCart?: boolean;
}

const Layout = (props: Props) => {
    const {crumbs, children, location, metadatas, hasWave, waveBgColor, headerColor, breadcrumbColor, hideContact, classNameHeader, disableMiniCart} = props;

    const siteMetadata = useStaticQuery(graphql`query{
        site {
            pathPrefix
            siteMetadata {
                siteUrl
            }
        }
    }`);

    const defaultMetaData: IMetadata = {
        title: crumbs[crumbs.length - 1].label + "| HIS Print & Service",
        description: "",
        ogTitle: "HIS Print & Service",
        ogImage: ogImage,
        ogDescription: "Ihr Partner für Drucke und Beschriftungen für Gewerbe und Industrie",
    }

    const metaData: IMetadata = defaultsDeep(metadatas, defaultMetaData);
    
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaData.title}</title>
            {metaData.description && <meta name="description" content={metaData.description} />}
            <meta property="og:locale" content="de_DE" />
            {location && location.pathname && <meta property="og:url" content={siteMetadata.site.siteMetadata.siteUrl + location.pathname}  />}
            <meta property="og:site_name" content="HIS Print & Service - Druck und Werbetechnik" />
            <meta property="og:image"  content={siteMetadata.site.siteMetadata.siteUrl.replace("https://", "http://") + siteMetadata.site.pathPrefix + metaData.ogImage.replace(siteMetadata.site.pathPrefix, "") + '?v=' + Date.now()}  />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaData.ogTitle ? metaData.ogTitle : metaData.title}  />
            <meta property="og:description" content={metaData.ogDescription ? metaData.ogDescription : metaData.description}  />
            

            <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
            <link rel="icon" type="image/png" sizes="64x64" href={favicon64} />
        </Helmet>
                <Gdpr />

                <Header className={classNameHeader} breadcrumbColor={breadcrumbColor} crumbs={crumbs} hasWave={hasWave} waveBgColor={waveBgColor} color={headerColor} />
                {!disableMiniCart && <MiniCart />}
                {disableMiniCart && <div></div>}
                
                {children}

                {!hideContact && <Contact />}
                <Footer />
    </>
}

export default Layout;