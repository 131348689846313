import React from 'react';
import { Link } from 'gatsby';
import './ButtonLink.scss';
import classnames from 'classnames';

type Props = {
    children: any;
    icon?: "arrow" | "send" | "none";
    to?: string;
    onClick?: any;
    className?: [string] | string;
}

export default (props: Props) => {
    const {children, icon, to, onClick, className } = props;

    let buttonClasses = ["button", "is-primary", "is-rounded", (icon) ? "has-icon-" + icon : "has-icon-arrow", ...(Array.isArray(className) ? className : [className])];

    const buttonContent = <>
        <span>{(children) ? children : null}</span>

        {icon !== "none" && <span className="icon is-small">
            {(!icon || icon === "arrow") && <svg width="30" height="13" viewBox="0 0 30 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.991,6.168l-12.616,0" style={{stroke:"#fff",strokeWidth: "0.75px"}}/>
                <path d="M12.094,0.375l6.145,6.027l-6.145,6.092" style={{fill: "none", stroke:"#fff",strokeWidth: "0.75px"}} />
            </svg>}

            {icon && icon === "send" && <svg width="13" height="13" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                <path d="M12.342,0.446l-5.556,11.977l-2.077,-4.233l-4.517,-1.756l12.15,-5.988Z"style={{fill: "none", stroke:"#fff",strokeWidth: "0.75px"}} />
                <path d="M12.163,0.884l-7.05,7.152"style={{fill: "none", stroke:"#fff",strokeWidth: "0.75px"}}/>
            </svg>}
        </span>}
    </>;

    if (to) {
        return <Link to={to} onClick={onClick} className={classnames(buttonClasses)}>
            {buttonContent}
        </Link>
    }

    return <button onClick={onClick} className={classnames(buttonClasses)}>
            {buttonContent}
        </button>
    
}