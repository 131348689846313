import React from 'react';
import { Link } from 'gatsby';
import * as styles from './Logo.module.scss';
import classnames from 'classnames';
import LogoWithGradient from '../../assets/logo.inline.svg';

type Props = {
    color?: "white" | "default";
}

const Logo = (props: Props) => {
    const [logoSize, setLogoSize] = React.useState<"normal" | "small">("normal");
    const {color} = props;

    // Scrollbehaviour and burger animation
    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', scrollListener);
        }
        

        return () => {
            if (typeof window !== `undefined`) {
                window.removeEventListener('scroll', scrollListener)
            }
        }
    })

    const scrollListener = () => {
        if (typeof window !== `undefined`) {
            const startAtY = 0;
            const way = 47;

            if (way + startAtY < window.pageYOffset) {
                setLogoSize("small");
            } else {
                setLogoSize("normal");
            }
        }
    }

    return <Link
    to="/"
    className={classnames([styles.logoWrapper, (logoSize === "small" ? styles.logoSmall : "")])}>
        <LogoWithGradient className={classnames([styles.logo, color === "white" ? styles.isWhite : ""])} height="110px" />
</Link>
};

export default Logo;