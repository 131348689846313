import React from 'react';
import * as styles from './ToTop.module.scss';
import classnames from 'classnames';

const ToTop = () => {

    const [visible, setVisible] = React.useState<boolean>(false);

    const moveToTop = (e) => {
        e.preventDefault();
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }


    // Scrollbehaviour and burger animation
    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', scrollListener);
        }
        

        return () => {
            if (typeof window !== `undefined`) {
                window.removeEventListener('scroll', scrollListener)
            }
        }
    })

    const scrollListener = () => {
        if (typeof window !== `undefined`) {
            const startAtY = 72;

            if (startAtY > window.pageYOffset) {
                setVisible(false);
            } else {
                setVisible(true);
            }
        }
    }

    return <a
        href="#"
        onClick={moveToTop}
        className={classnames([styles.toTop, (visible ? styles.visible : "")])}>
            <svg className="verticalFoldChevron" width="18px" height="10px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7.646,0.375l-3.616,3.687l-3.655,-3.687" style={{fill: "none", fillRule:"nonzero", stroke: "#009ee0", strokeWidth: 0.75}} /></svg>
    </a>
};

export default ToTop;