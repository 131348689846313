import React from 'react';
import { Breakpoints } from './Types';


const calculateBreakpoint = (): Breakpoints => {

    if (typeof window !== `undefined`) {
        const windowWidth: number = window.innerWidth;

        if (windowWidth <= 768) {
            return "mobile";
        } else if (windowWidth > 768 && windowWidth < 1024) {
            return "tablet";
        } else if (windowWidth >= 1024) {
            return "desktop";
        }
    }
    return undefined;
}

export default function useBreakpoint() {



    const [breakpoint, setBreakpoint] = React.useState(calculateBreakpoint());

    const resizeListener = () => {
        setBreakpoint(calculateBreakpoint());
    }

    // initial call of the listener
    React.useEffect(() => {
        setBreakpoint(calculateBreakpoint());
    }, []);

    // Call listener on resize
    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            window.addEventListener('resize', resizeListener);
        }
        

        return () => {
            if (typeof window !== `undefined`) {
                window.removeEventListener('resize', resizeListener)
            }
        }
    });

    return breakpoint;
}