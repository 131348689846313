import React from 'react';
import * as styles from "./Wave.module.scss";
import classnames from 'classnames';
import { Colors, Breakpoints } from '../../utils/Types';

type Props = {
    color?: Colors;
    variation?: "top";
    showOn?: Array<Breakpoints>;
}

export default function Wave(props: Props) {
    const { color, variation } = props;

    const showOn: Array<Breakpoints> = (props.showOn) ? props.showOn : [];

    const colors = {
        dark: "#364148",
        light: "#D2DBE1",
        primary: "#009EE0",
        link: "#E5730A",
        "dark-blue": "#1D4A68",
        white: "#fff",
    }

    
    return <div className={classnames([
        styles.wave,
        variation === "top" ? styles.waveSvgTop : "",
        ...showOn.map(bp => styles["hasWave" + bp.charAt(0).toUpperCase() + bp.slice(1)]), styles.wave,
        ])}>
                <svg className={classnames([styles.waveSvg]) } width="100%" height="100%"  viewBox="0 0 1 1" preserveAspectRatio="none">
                    <path d="M1,1l-0.252,0c0.177,0 0.252,-0.194 0.252,-0.562l0,0.562Zm-1,-0.761c0.21,0.457 0.571,0.761 0.748,0.761l-0.748,0l0,-0.761Z" fill={(color) ? colors[color] : "#fff"} />
                    {/* <path d="M0,0l1,0l0,0.438c0,0.368 -0.075,0.562 -0.252,0.562c-0.177,0 -0.426,-0.188 -0.748,-0.563l0,-0.437Z"/> */}
            </svg>
        </div>
}