import React from 'react';
import * as styles from './Section.module.scss';
import classnames from 'classnames';
import { Sizes } from '../../../utils/Types';

type Props = {
    children?: any;
    size?: Sizes
    style?: any;
    className?: string | Array<string>;
    id?: string;
    hasTitle?: boolean;
}

export default (props: Props) => {
    const {children, size, style, className, id, hasTitle} = props;
    let isContent: string = "";
    if (size) {
        switch (size) {
            case "extraSmall": isContent = "is-8-desktop is-offset-2-desktop"; break;
            case "small": isContent = "is-10-desktop is-offset-1-desktop"; break;
        }
    }

    return <section 
        id={id} 
        className={classnames([
            "section", 
            styles.section, 
            ...(Array.isArray(className)) ? className : [className],
            hasTitle ? styles.biggerMargin : "",
        ])} 
        style={style}
    >
            <div className="container">
                {size && <div className="columns">
                    <div className={classnames(["column", isContent])}>
                        {(children) ? children : null}
                    </div>
                </div>}
                {(!size && children) ? children : null}
            </div>
    </section>
}