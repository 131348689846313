// extracted by mini-css-extract-plugin
export var navMobile = "Header-module--navMobile--s3H9E";
export var crumbs = "Header-module--crumbs--2uMNA";
export var container = "Header-module--container--3KhaC";
export var breadcrumbWrapper = "Header-module--breadcrumbWrapper--3GVV2";
export var navbarBrand = "Header-module--navbarBrand--2k91t";
export var navbarBackground = "Header-module--navbarBackground--3ohZx";
export var wave = "Header-module--wave--1rd_G";
export var spacer = "Header-module--spacer--2jTt4";
export var navbarBurger = "Header-module--navbarBurger--22u29";
export var navbarBurgerScrolled = "Header-module--navbarBurgerScrolled--2IFrM";
export var spinAround = "Header-module--spinAround--2pVvL";