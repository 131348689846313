import React from 'react';
import ButtonAction from '../ui/Buttons/ButtonAction';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { useFormik } from 'formik';
import Axios from 'axios';
import { string, boolean, object } from 'yup';

export default function ContactForm () {

    const validationSchema = object({
        name: string().required("Wir benötigen Ihren Namen um Rückfragen zuordnen zu können."),
        email: string().required("Wird benötigt um mit Ihnen das Angebot zu schicken.").email("Bitte eine korrekte E-Mail Adresse eingeben!"),
        message: string().required("Bitte teilen Sie uns Ihr Anliegen mit!"),
        dataprivacy: boolean().oneOf([true],"Bitte die Datenschutzbestimmungen akzeptieren."),
    });

    const [transmitionState, setTransmitionState] = React.useState<"open" | "sending" | "done">("open");
    const [error, setError] = React.useState<React.ReactElement>(null);

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          message: "",
          dataprivacy: false,
        },
        validationSchema,
        onSubmit: async values => {
            setTransmitionState("sending");
            try {
                const result = await Axios.post(process.env.API_URL + 'contact/simple', values);
                setTransmitionState("done");

            } catch (e) {
                setTransmitionState("open");
                setError(<>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder senden Sie uns eine E-Mail an: <a href={"mailto:info@hisprint.de?subject=Anfrage über ein Anliegen&body=Hallo liebes HIS Team,%0D%0A %0D%0A Ich habe folgendes Anliegen: %0D%0A" + formik.values.message + " %0D%0A %0D%0A Viele Grüße %0D%0A" + formik.values.name}>info@hisprint.de</a>. Fehler: {e.message}</>);
            }
            

            
        },
      });

      const showError = (field: string) => {
          return (formik.touched[field] && formik.errors[field]);
      }

      if (transmitionState === "done") {
          return <div className="highlight">
              Vielen Dank! Wir haben Ihr Anliegen erhalten und werden uns schnellstmöglich darum kümmern und uns bei Ihnen per E-Mail melden.
          </div>
      }

    return   <form onSubmit={formik.handleSubmit}>

        {error && <div className="notification is-danger">
            {error}
        </div>}

    <div className="field">
        <div className="control">
            <input name="name" className={classnames(["input", (showError("name") ? "is-danger": "")])} placeholder="Wie lautet Ihr Name?" type="text" value={formik.values.name} onChange={formik.handleChange} />
        </div>
        {showError("name") && <p className={classnames(["help", "is-danger"])}>{formik.errors.name}</p>}
    </div>

    <div className="field">
        <div className="control">
            <input  name="email" className={classnames(["input", (showError("email") ? "is-danger": "")])} placeholder="Ihre E-Mail Adresse" type="email" value={formik.values.email}  onChange={formik.handleChange} />
        </div>
        {showError("email") && <p className={classnames(["help", "is-danger"])}>{formik.errors.email}</p>}
    </div>

    <div className="field">
        <div className="control">
            <textarea name="message"  className={classnames(["textarea", (showError("message") ? "is-danger": "")])} placeholder="Wie können wir Ihnen weiterhelfen?" value={formik.values.message}  onChange={formik.handleChange}></textarea>
        </div>
        {showError("message") && <p className={classnames(["help", "is-danger"])}>{formik.errors.message}</p>}
    </div>

    <p>
    <label className={classnames([(showError("dataprivacy")) ? "has-text-danger" : "", "checkbox"])}>
        <input 
            name="dataprivacy" 
            type="checkbox" 
            onChange={(props: any) => {
                formik.setFieldValue("dataprivacy", !formik.values.dataprivacy, true);
            }} 
            checked={formik.values.dataprivacy}  
        />
        {showError("dataprivacy") && <>{formik.errors.dataprivacy}</>} Die abgesendeten Daten werden nur zum Zweck der Bearbeitung Ihres Anliegens verarbeitet. Weitere Informationen finden Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.
    </label>
    </p>
    
    <div className="has-text-right">
        <ButtonAction className={transmitionState === "sending" ? "is-loading" : ""} icon="send">Absenden</ButtonAction>
    </div>
    
</form>

}