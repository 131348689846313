import React from 'react';
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as styles from './Hero.module.scss';
import './Hero.scss';
import classnames from 'classnames';
import Wave from '../Wave';
import useBreakpoint from '../../../utils/useBreakpoint';
import { Colors, Breakpoints, Sizes } from '../../../utils/Types';
import BreakpointRender from '../../../utils/BreakpointRender';

type Props = {
    children?: any;
    title?: string;
    subtitle?: any;

    color?: Colors;
    colorDesktop?:  Colors;
    backgroundImage?: IGatsbyImageData;
    backgroundVideo?: string;
    backgroundDotted?: boolean;

    hasWaveTop?: boolean |  Array<Breakpoints>;
    waveTopColor?: Colors;
    hasWaveBottom?: boolean | Array<Breakpoints>;
    waveBottomColor?: Colors;

    hasRoundedCorner?: boolean;

    isExpandingRightOnly?: "IsFull" | "IsOneThird"; // Onlye moves the hero to the right side not to the left!
    
    paddingSize?: "normal" | "large";
    size?: Sizes;
    style?: any;
    className?: any;
    id?: string;
}

export default (props: Props) => {
    const {children, id, style, className, title, subtitle, color, backgroundImage, backgroundVideo, colorDesktop, size, waveBottomColor, waveTopColor, backgroundDotted, hasRoundedCorner, isExpandingRightOnly, paddingSize} = props;
    const breakpoint: Breakpoints = useBreakpoint();
    
    const hasWaveTop: Array<Breakpoints> =  (Array.isArray(props.hasWaveTop)) ? props.hasWaveTop : (props.hasWaveTop === true) ? ["desktop", "tablet", "mobile"] : []; 
    const hasWaveBottom: Array<Breakpoints> =  (Array.isArray(props.hasWaveBottom)) ? props.hasWaveBottom : (props.hasWaveBottom === true) ? ["desktop", "tablet", "mobile"] : []; 

    // different color for desktop or use default if not set
    const [heroColor, setHeroColor]: [string, Function] = React.useState("is-" + ((color) ? color : "light"));

    // set offset variable depending on size attribute
    let divOffset: string = "";
    if (size) {
        switch (size) {
            case "extraSmall": divOffset = "is-8-desktop is-offset-2-desktop"; break;
            case "small": divOffset = "is-10-desktop is-offset-1-desktop"; break;
        }
    }

    React.useEffect(() => {
        setHeroColor((colorDesktop && ["desktop"].indexOf(breakpoint) !== -1) ? "is-" + colorDesktop : ("is-" + ((color) ? color : "light")));
    }, [breakpoint]);

    return (
        <div className={classnames([
                ...(Array.isArray(className) ? className : [className]),
                "hero", 
                heroColor, 
                (backgroundImage && ["desktop"].indexOf(breakpoint) !== -1) ? " " + styles.hasBackgroundImage : "",
                styles.hero,
                backgroundDotted ? styles.hasBackgroundDotted : "",
                hasRoundedCorner ? styles.hasRoundedCorner : "",
            ])}
            style={style}
            id={id}
            >

            {<Wave color={waveTopColor} variation="top" showOn={hasWaveTop} />}

            {backgroundImage && <BreakpointRender visibleOn={["desktop"]}>
                <GatsbyImage
                    image={backgroundImage}
                    objectFit={"cover"}
                    className={styles.backgroundImage}
                    alt="" 
                />
            </BreakpointRender>}

            {backgroundVideo && <BreakpointRender visibleOn={["desktop"]}>
                <div className={styles.videoWrapper}>
                    <video loop={true} muted={true} className={styles.video} autoPlay={true}>
                        <source src={backgroundVideo} type="video/mp4" />
                    </video>
                </div>
            </BreakpointRender>}

            <div className={classnames([
                "hero-body", 
                "is-two-fifths", 
                ...hasWaveTop.map(bp => "has-no-wave-top-" + bp),  
                ...hasWaveBottom.map(bp => "has-no-wave-bottom-" + bp),
                ])}>
                <div 
                    className={classnames([
                        "container", 
                        isExpandingRightOnly ? styles["expandContainer" + isExpandingRightOnly] : "",
                    ])}
                >
                    {(title || subtitle) &&<div className="columns">
                        <div className="column is-1 is-hidden-touch">

                        </div>
                        <div className="column is-two-thirds-tablet is-three-fifths-desktop">
                            {title && <h1 className={classnames("title", styles.h1)}>
                                {title}
                            </h1>}

                            {subtitle && <h2 className=" subtitle">
                                {subtitle}
                            </h2>}
                        </div>
                    </div>}
                    
                    {children && <>
                        {divOffset &&<div className="columns">
                            <div className={classnames(["column", divOffset])}>
                                {children}
                            </div>
                        </div>}

                        {!divOffset && <>
                            {children}
                        </>}
                    </>}


                    <div className={classnames( [...["mobile", "tablet", "desktop"].map(bp => {
                        let found = false;

                        hasWaveBottom.forEach(i => {
                            if (i === bp) {
                                found = true;
                            }
                        });

                        if (!found) {
                            return  "is-hidden-" + bp + "-only";
                        }
                        })])}>

                    </div>
                </div>
            </div>
            {<Wave color={waveBottomColor} showOn={hasWaveBottom} />}
        </div>
    );
}