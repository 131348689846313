import React from 'react';
import { CartContext } from './CartContext';
import * as styles from './CartButton.module.scss';
import { Link } from 'gatsby';
import useBreakpoint from '../../utils/useBreakpoint';

export default () => {

    const { products, dispatch } = React.useContext(CartContext);
    const breakpoint = useBreakpoint();
    const hovering = React.useRef<boolean>(false);

    // Show miniCart
    const showMiniCart = () => {
        hovering.current = true;
        if (breakpoint === "desktop") {
            setTimeout(() => {
                if (hovering.current && document.getElementById("miniCart")) {
                    dispatch({type: "OPEN_MINI_CART"});
                }
            }, 1000);
        }
    }

    const mouseLeave = () => {
        hovering.current = false;
    }

    return <div className={styles.wrapper}>
        {/* <a href="#" onClick={() => { dispatch({type: "OPEN_MINI_CART"}) }} className="navbar-item navbar-item-top"> */}
        <Link className="navbar-item navbar-item-top" to="/anfragen" onMouseOver={showMiniCart} onMouseLeave={mouseLeave}>
                 Anfragen
                <svg width="17" height="21" viewBox="0 0 16 19" version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit:10}}>
                    <path d="M3.767,3.376l-2.103,0c-0.171,0 -0.315,0.131 -0.331,0.302l-0.958,10.809l0,0.03c0,1.159 1.068,2.1 2.384,2.1l8.381,0c1.315,0 2.383,-0.941 2.383,-2.1l0,-0.03l-0.96,-10.809c-0.017,-0.171 -0.158,-0.302 -0.332,-0.302l-2.103,-0.077"  style={{fill: "none", fillRule: "nonzero", stroke: "#009ee0", strokeWidth: "0.75px"}} /><g><clipPath id="_clip1"><path d="M4.113,5.66c-0.515,-3.523 0.473,-5.285 2.965,-5.285c2.492,0 3.438,1.762 2.836,5.285l-5.801,0Z"/></clipPath><g clipPath="url(#_clip1)"><clipPath id="_clip2"><rect x="-1.43" y="-0.633" width="16.5" height="18" clipRule="nonzero"/></clipPath><g clipPath="url(#_clip2)"><rect x="0.231" y="-3.375" width="13.609" height="12.785" style={{fill: "none"}} /></g></g></g><path d="M4.113,5.66c-0.515,-3.523 0.473,-5.285 2.965,-5.285c2.492,0 3.438,1.762 2.836,5.285"  style={{fill: "none", fillRule: "nonzero", stroke: "#009ee0", strokeWidth: "0.75px"}}/>
                    {products.length > 0 && <g transform="translate(2 0) scale(1.3)">
                        <circle cx="6.949" cy="10.812" r="3.88" style={{fill: "#bbca8e"}}/>
                        <path d="M8.889,9.648l-2.381,2.307c-0.028,0.028 -0.074,0.028 -0.103,0.001l-1.137,-1.072" style={{fill: "none", fillRule: "nonzero", stroke: "#fff", strokeWidth: "1px"}}/>
                    </g>}
                    
                </svg>
        </Link>
        {/* </a> */}
    </div>
}