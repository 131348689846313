import React from 'react';
import { Breakpoints } from './Types';
import useBreakpoint from './useBreakpoint';

type Props = {
    children: any;
    visibleOn: Array<Breakpoints>;
}

export default function BreakpointRender (props: Props) {
    const {children, visibleOn} = props;
    const breakpoint: Breakpoints = useBreakpoint();

    const [components, setComponents] = React.useState(null);

    React.useEffect(() => {
        if (breakpoint && visibleOn.indexOf(breakpoint) !== -1) {
            setComponents(children);
        } else {
            setComponents(null);
        }
    }, [breakpoint]);

    return components;
}