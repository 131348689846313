import React from 'react';
import { CartContext } from './CartContext';
import * as styles from './MiniCart.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import ButtonAction from '../ui/Buttons/ButtonAction';
import ButtonLink from '../ui/Buttons/ButtonLink';
import classnames from 'classnames';

export default function MiniCart() {

    const { products, dispatch, miniCartOpen } = React.useContext(CartContext);
    const closeDelayTimout = React.useRef(null);

    const onDocumentClick = () => {
        if (miniCartOpen) {
            dispatch({type: "CLOSE_MINI_CART"});
        }
    }

    const onCartClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    React.useEffect(() => {
        document.addEventListener('click', onDocumentClick);

        return () => {
            document.removeEventListener('click', onDocumentClick);
            onMouseOver();
        }
    });

    const onMouseLeave = () => {
        closeDelayTimout.current = setTimeout(() => {
            onDocumentClick();
        }, 1500);
    }

    const onMouseOver = () => {
        if (closeDelayTimout.current) {
            clearTimeout(closeDelayTimout.current);
        }
    }

    // keep open if miniCartOpen is triggered
    React.useEffect(() => {
        if (miniCartOpen) {
            onMouseOver();
        }
    }, [miniCartOpen]);

    return (
        <div id="miniCart" className={classnames([styles.miniCartWrapper, miniCartOpen ? styles.open : ""])} onMouseLeave={onMouseLeave} onMouseOver={onMouseOver} >
                <div onClick={onCartClick} className={classnames([styles.miniCart, "has-text-grey"])}>
                    <div className="content">
                            <h3>Ihre Anfrage</h3>

                            {products.length <= 0 && <>
                                <p>Fügen Sie wie in einem Warenkorb Produkte hinzu um Ihre Anfrage aussagekräftig zu machen.</p>
                                <ButtonLink to="/produkte" onClick={() => { dispatch({type: "CLOSE_MINI_CART"}) } }>Produkte auswählen</ButtonLink>
                            </>}
                    </div>

                    
                    
                    {products.length > 0 && <>

                        <div className={styles.productList}>
                            {products.map(product => (
                                <div className="columns is-mobile" key={product.product.name + "-" + JSON.stringify(product.configuration)}>
                                    {product.product.listenbild.src && <div className="column is-4">
                                        <GatsbyImage image={product.product.listenbild.src.childImageSharp.gatsbyImageData} alt={product.product.listenbild.alt} />
                                    </div>}
                                    <div className="column">
                                        <div className="columns">
                                            <div className="column">
                                                <span className="title is-size-4 has-hyphens">{product.product.name}</span><br />
                                                <span>{product.qty} Stück</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                        <div className={styles.actionBar} data-testid="minicart-goto-cart">
                            <ButtonAction to="/anfragen" onClick={() => { dispatch({type: "CLOSE_MINI_CART"}) } } className={"is-fullwidth"}>Zur Preisanfrage</ButtonAction>
                        </div>
                    </>}

                </div>
            </div>
    );
}