import React from "react"
import { Link } from "gatsby";
import './Breadcrumb.scss';
import classnames from "classnames";

type Props = {
    crumbs: Array<{ label: string; url: string }>
    fontColor: "dark" | "light";
}

export default (props: Props) => {
    const { crumbs, fontColor } = props

    let path:string = "/";
    return (
                    <nav className={classnames(["breadcrumb", "is-5", "has-succeeds-separator", (fontColor === "light") ? "has-text-white" : "has-text-dark"])} aria-label="breadcrumbs">
                        <ul>
                            {crumbs.map((item, i) => {
                                path += ((path.slice(-1) !== "/") ? "/" : "") + item.url;
                                return (<li className={(i+1 === crumbs.length) ? "is-active" : ""} key={i}>
                                    <Link to={path}>{item.label}</Link>
                                </li>)
                            })}
                        </ul>
                    </nav>
        
    )
}
