import React from "react"
import "../../styles/styles.scss"
import * as styles from "./Header.module.scss";
import './Header.scss';
import { Link } from "gatsby";
import CartButton from "../Cart/CartButton";
import Wave from "../ui/Wave";
import Breadcrumb from "./Breadcrumb";
import classNames from "classnames";
import BurgerButton from "./BurgerButton";
import { Colors, Breakpoints } from "../../utils/Types";
import Logo from "./Logo";
import ToTop from "./ToTop";



type Props = {
    crumbs: Array<{ label: string; url: string }>;
    hasWave?: boolean | Array<Breakpoints>;
    waveBgColor?: Colors;
    color?: Colors;
    noPadding?: boolean;
    breadcrumbColor?: "light" | "dark";
    className?: string | Array<string>;
}

export default (props: Props) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [scrolled, setScrolled] = React.useState(false);
    const [burgerOpacity, setBurgerOpacity] = React.useState(0);
    const {color, breadcrumbColor, waveBgColor, className } = props;
    const hasWave: Array<Breakpoints> = (Array.isArray(props.hasWave)) ? props.hasWave : props.hasWave === false ? [] : ["mobile", "tablet", "desktop"];
    const [fontColor, setFontColor] = React.useState([54, 65, 72]);


    // Scrollbehaviour and burger animation
    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', scrollListener);
        }
        

        return () => {
            if (typeof window !== `undefined`) {
                window.removeEventListener('scroll', scrollListener)
            }
        }
    })

    const scrollListener = () => {
        if (typeof window !== `undefined`) {
            const startAtY = 70;
            const scrollOffset = (window.pageYOffset < startAtY && window.pageYOffset >= 0) ? 0 : window.pageYOffset - startAtY;
            const percent = (Math.pow(scrollOffset, 2) / 100);
            setBurgerOpacity((percent > 100) ? 100 : percent);
    
            if (window.pageYOffset > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
    }


    // Font color per background
    React.useEffect(() => {
        if (color === "light") {
            setFontColor([27, 157, 220]);
        } else {
            setFontColor([255, 255, 255]);
        }
    }, [color, scrolled]);

    // Calculate color of burger stripes
    const getBurgerColor = () => {
        if (menuOpen) {
            return "#fff";
        }
        return "RGB(" + (fontColor[0] + (255-fontColor[0]) * burgerOpacity/100) + ", " + (fontColor[1] + (255-fontColor[1]) * burgerOpacity/100)  + ", " + (fontColor[2] + (255-fontColor[2]) * burgerOpacity/100)  + ")";
    }

    // calculate burger icon background
    const getBurgerBgColor = () => {
        let opacity = burgerOpacity;
        if (menuOpen) {
            opacity = 0;
        }
        return "RGBA(0, 158, 224, " + opacity/100 + ")";
    }

    // Toggle Menü
    const toggleMenu  = () => {
        setMenuOpen(!menuOpen);
    }

    const closeMenu = () => {
        setMenuOpen(false);
    }



    return (<>
        <nav className={classNames([styles.navbarBackground, "navbar", props.color ? "is-" + color : "is-dark", className])} role="navigation" aria-label="main navigation">


            <div className={classNames(styles.container, "container")}>
                <div className={classNames(["navbar-brand", styles.navbarBrand])}>
                    <Logo color={color === "light" ? "default" : "white"} />
                    
                    <ToTop />

                    <button
                        className={classNames("navbar-burger" , "burger", ((menuOpen) ? "is-active" : ""), styles.navbarBurger, (scrolled) ? styles.navbarBurgerScrolled : "" )}
                        style={{background: getBurgerBgColor() }}
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navigation"
                        onClick={toggleMenu}
                    >
                        <BurgerButton active={menuOpen} color={getBurgerColor()} />
                    </button>
                </div>

                <div id="navigation" className={"navbar-menu " + ((menuOpen) ? "is-active" : "" ) + " " + styles.navMobile}>

                    <div className="navbar-end">
                        <div className="navbar-end-row menu-actions">
                            <Link to="/suche"  className="navbar-item navbar-item-top" >
                                Suche  
                                <svg width="21" height="21" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"  style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit:10}} ><path d="M11.517,8.026c0,0.967 -0.387,1.842 -1.013,2.479c-0.636,0.647 -1.519,1.048 -2.496,1.048c-1.937,0 -3.508,-1.579 -3.508,-3.527c0,-1.947 1.571,-3.526 3.508,-3.526c1.938,0 3.509,1.579 3.509,3.526Z"  style={{fill: "none", fillRule: "nonzero", stroke: "#009ee0", strokeWidth: "0.75px"}}/><path d="M12.87,12.983l-2.366,-2.478" style={{fill: "none", fillRule: "nonzero", stroke: "#009ee0", strokeWidth: "0.75px"}}/><path d="M8.625,0.375c4.556,0 8.25,3.694 8.25,8.25c0,4.556 -3.694,8.25 -8.25,8.25c-4.556,0 -8.25,-3.694 -8.25,-8.25c0,-4.556 3.694,-8.25 8.25,-8.25Z"  style={{fill: "none", fillRule: "nonzero", stroke: "#009ee0", strokeWidth: "0.75px"}}/></svg>
                            </Link>
                            <CartButton />
                        </div>
                        <div className="navbar-end-row">
                            <Link onClick={closeMenu} className="navbar-item navbar-item-bottom is-hidden-desktop" activeClassName="active" to="/">Home</Link>
                            <Link onClick={closeMenu} className="navbar-item navbar-item-bottom" activeClassName="active" to="/unternehmen" partiallyActive={true}>Unternehmen</Link>
                            <Link onClick={closeMenu} className="navbar-item navbar-item-bottom" activeClassName="active" to="/leistungen" partiallyActive={true}><span className="is-hidden-desktop">Leistungen</span><span className="is-hidden-touch">Unser Leistungsspektrum</span></Link>
                            <Link onClick={closeMenu} className="navbar-item navbar-item-bottom" activeClassName="active" to="/produkte" partiallyActive={true}>Produkte</Link>
                            <Link onClick={closeMenu} className="navbar-item navbar-item-bottom" activeClassName="active" to="/fallbeispiele" partiallyActive={true}>Fallbeispiele</Link>
                        </div>
                    </div>
                </div>

            </div>
            {hasWave.length > 0 && <div className={styles.wave}>
                <Wave showOn={hasWave} color={waveBgColor} />
            </div>}

        </nav>
        <div className={classNames(["container", styles.breadcrumbWrapper])}>
            <div className={styles.crumbs}>
                <Breadcrumb fontColor={breadcrumbColor} crumbs={props.crumbs} />
            </div>
        </div>

    </>
    )
}
