import React from 'react';
import * as styles from './Contact.module.scss';
import Hero from './ui/Hero/Hero';
import ContactForm from './forms/ContactForm';

export default () => {

    return (
        <Hero
            hasWaveTop={true}
            waveTopColor={"white"}
            color={"light"}
            style={{marginBottom: 0,}}
        >

                    <div className="columns">
                        <div className="column">
                            <div className="content">
                            <h3 className="title is-3">Lassen Sie sich beraten!</h3>
                            <p className="subtitle">
                                Es gibt für alles eine Lösung! Sie ist bis jetzt nur noch nicht hier aufgelistet! Wir finden immer das richtige Produkt mit der besten Beschriftung. Lassen Sie sich beraten! 
                            </p>
                            </div>

                            <div className={styles.phone}>
                                Rufen Sie uns an, wir helfen Ihnen gerne weiter!<br />
                                <a href="tel:00498283998100">+49 (0) 82 83 / 9 98 10 - 0</a><br />
                                <br />
                                <span style={{whiteSpace: "pre"}}>
                                    Mo. - Do.  08:00 bis 17:00 Uhr<br />
                                    Fr.              08:00 bis 13:00 Uhr
                                </span>
                                    
                            </div>
                        </div>

                        <div className="column">
                                <ContactForm />
                        </div>
                    </div>

        </Hero>
    )
}