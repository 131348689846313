import React from 'react';

import * as styles from './Gdpr.module.scss';
import { GdprContext } from './GdprContext';
import GdprSettings from './GdprSettings';

export default function Gdpr ()  {
    const {dispatch, hideDialog, hideSettings, settings} = React.useContext(GdprContext);

    const accept = () => {
        dispatch({type: "ACCEPT_ALL"});
        dispatch({type: "HIDE_DIALOG"});
    }

    const openSettings = (e) => {
        e.preventDefault();
        dispatch({type: "SHOW_SETTINGS"});
    }

    return <>
        {!hideSettings && <GdprSettings />}

        {!hideDialog && <div className={styles.modal}>
                <div className="container">
                    <div className="content">
                        <div className="columns is-2 is-vcentered">

                            <div className="column ">
                                <div className="content">
                                    <span className="title is-size-4">Datenschutz</span><br />
                                    <span className="is-size-7">Unsere Website lässt sich komplett ohne Cookies bedienen. Dennoch
                                    bieten wir Ihnen Vorteile indem wir auf Ihrem Computer Daten setzen. Helfen Sie
                                    auch uns unseren Service zu verbessern indem Sie unsere vorgeschlagenen Einstellungen
                                    verwenden.</span>
                                </div>
                                
                            </div>

                            <div className="column is-narrow">
                                <div className="columns is-vcentered is-mobile">
                                    <div className="column">
                                        <a href="#" onClick={openSettings}>Einstellungen</a>
                                    </div>
                                    <div className="column">
                                        <button className="button is-primary" onClick={accept}>Alle Akzeptieren</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>}
    </>
}