import React from 'react';
import { GdprContext } from './GdprContext';
import classnames from 'classnames';

type Props = {
    className?: Array<string> | string;
}

export default function GdprDialogButton (props: Props) {

    const {dispatch } = React.useContext(GdprContext);
    const className = (props.className && Array.isArray(props.className)) ? props.className : [props.className];

    const openDialog = (e) => {
        e.preventDefault();

        dispatch({type: "SHOW_SETTINGS"});
    }

    return <a href="#" onClick={openDialog} className={classnames(className)}>Privatsphäre Einstellungen</a>
}