import React from 'react';
import { GdprContext } from './GdprContext';
import {checkbox, smallButton} from './GdprSettings.module.scss';
import classnames from 'classnames';

export default function GdprSettings () {

    const { dispatch, settings, changed } = React.useContext(GdprContext);
    
    let initialSettings = {};
    for (const service of settings.services) {
        if (changed) {
            initialSettings[service.name] = service.allowed;
        } else {
            initialSettings[service.name] = service.default;
        }
       
    }

    const [settingsForm, setSettingsForm] = React.useState(initialSettings);


    const closeSettings = (e) => {
        e.preventDefault();
        dispatch({type: "HIDE_SETTINGS"});
        dispatch({type: "HIDE_DIALOG"});
    }

    const changeCheckbox = (key: string) => {
        let newS = {...settingsForm};
        newS[key] = !settingsForm[key];
        setSettingsForm(newS);
    }

    const save = () => {

        dispatch({
            type: "SAVE_SETTINGS",
            settings: settingsForm,
        });
        dispatch({type: "HIDE_SETTINGS"});
        dispatch({type: "HIDE_DIALOG"});
    }

    const saveAll = () => {

        dispatch({
            type: "ACCEPT_ALL",
        });
        dispatch({type: "HIDE_SETTINGS"});
        dispatch({type: "HIDE_DIALOG"});
    }


    return <div className="modal is-active">
        <div className="modal-background"  onClick={closeSettings}></div>
  <div className="modal-card">
    <header className="modal-card-head">
      <p className="modal-card-title" style={{marginBottom: 0}}>Privatsphäre Einstellungen</p>
      <button className="delete" aria-label="close" onClick={closeSettings}></button>
    </header>
    <section className="modal-card-body">
        {settings.categories.map((category) => (
            <div key={category}>
                <span className="title">{category}</span>
                
                    {settings.services.map((service) => {
                        if (service.category !== category) {
                            return null;
                        }
                    
                    let dataInUse = "";
                    for (const cookie of service.cookies) {
                        if (dataInUse.length > 0) {
                            dataInUse += ", ";
                        }
                        dataInUse += cookie.type + ": " + cookie.name;
                    }

                        return <div className="columns is-mobile" key={category + "-" + service.name}>
                                <div className="column is-1 has-text-right">
                                    <label className="checkbox">
                                        <input className={checkbox} type="checkbox" checked={settingsForm[service.name]} onChange={() => { changeCheckbox(service.name); }} />
                                    </label>
                                </div>
                                <div className="column">
                                    <span className="is-size-3">{service.name}</span><br />
                                    <p>
                                        <span dangerouslySetInnerHTML={{__html: service.description}}></span><br />
                                    
                                        <span className="is-size-7"> Gesetzte Daten: {dataInUse} </span> 
                                        <span className="is-size-7">; Benuzte Services: {service.domain} </span>
                                    </p>
                                    
                                </div>
                                
                            </div>
                    })}
            </div>
        ))}
    </section>
    <footer className={classnames(["modal-card-foot"])}>
      <button className={classnames(["button", smallButton])} onClick={save}>Auswahl speichern</button>
      <button className="button is-primary" onClick={saveAll}>Alles speichern</button>
    </footer>
  </div>
    </div>
}